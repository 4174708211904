import AddIcon from '../../assets/images/add-no-fill.svg?react';
import AleloIcon from '../../assets/images/alelo.svg?react';
import AmexIcon from '../../assets/images/amex.svg?react';
import BackIcon from '../../assets/images/back.svg?react';
import CabalIcon from '../../assets/images/cabal.svg?react';
import CalendarIcon from '../../assets/images/calendar.svg?react';
import CheckIcon from '../../assets/images/check.svg?react';
import ChevronDownIcon from '../../assets/images/chevron-down.svg?react';
import ChevronIcon from '../../assets/images/chevron-right.svg?react';
import ClockIcon from '../../assets/images/clock.svg?react';
import CloseIcon from '../../assets/images/close.svg?react';
import EmptyStateIcon from '../../assets/images/customers/not-found.svg?react';
import DeleteIcon from '../../assets/images/delete.svg?react';
import EloIcon from '../../assets/images/elo.svg?react';
import EraserIcon from '../../assets/images/eraser.svg?react';
import WarningIcon from '../../assets/images/exclamation-close.svg?react';
import InformativeIcon from '../../assets/images/exclamation-open.svg?react';
import ExportIcon from '../../assets/images/export.svg?react';
import FilterIcon from '../../assets/images/filter.svg?react';
import HipercardIcon from '../../assets/images/hipercard.svg?react';
import ImportIcon from '../../assets/images/import.svg?react';
import MasterIcon from '../../assets/images/master.svg?react';
import MoneyIcon from '../../assets/images/money.svg?react';
import NotFoundIcon from '../../assets/images/not-found.svg?react';
import AverageIcon from '../../assets/images/payments/average.svg?react';
import CoinsIcon from '../../assets/images/payments/coins.svg?react';
import DepositsEmptyStateIcon from '../../assets/images/payments/depositsEmptyState.svg?react';
import PaymentsEmptyStateIcon from '../../assets/images/payments/paymentsEmptyState.svg?react';
import RegisterIcon from '../../assets/images/payments/register.svg?react';
import ReceiptIcon from '../../assets/images/receipt.svg?react';
import SearchIcon from '../../assets/images/search.svg?react';
import ShareIcon from '../../assets/images/share.svg?react';
import ErrorIcon from '../../assets/images/small-close.svg?react';
import SodexoIcon from '../../assets/images/sodexo.svg?react';
import TicketIcon from '../../assets/images/ticket.svg?react';
import VisaIcon from '../../assets/images/visa.svg?react';
import VRIcon from '../../assets/images/vr.svg?react';
import Bank from '../../assets/images/zoop-onboarding/bank.svg?react';
import Business from '../../assets/images/zoop-onboarding/business.svg?react';
import CheckBrokenIcon from '../../assets/images/zoop-onboarding/check-broken.svg?react';
import UserProfiles from '../../assets/images/zoop-onboarding/users-profiles.svg?react';

const ICONS = {
  delete: DeleteIcon,
  error: ErrorIcon,
  import: ImportIcon,
  informative: InformativeIcon,
  success: CheckIcon,
  warning: WarningIcon,
  calendar: CalendarIcon,
  eraser: EraserIcon,
  chevronDown: ChevronDownIcon,
  clock: ClockIcon,
  emptyState: EmptyStateIcon,
  money: MoneyIcon,
  visa: VisaIcon,
  master: MasterIcon,
  amex: AmexIcon,
  cabal: CabalIcon,
  hipercard: HipercardIcon,
  elo: EloIcon,
  alelo: AleloIcon,
  ticket: TicketIcon,
  sodexo: SodexoIcon,
  vr: VRIcon,
  register: RegisterIcon,
  average: AverageIcon,
  coins: CoinsIcon,
  paymentsEmptyState: PaymentsEmptyStateIcon,
  depositsEmptyState: DepositsEmptyStateIcon,
  notFound: NotFoundIcon,
  receipt: ReceiptIcon,
  export: ExportIcon,
  close: CloseIcon,
  chevron: ChevronIcon,
  search: SearchIcon,
  filter: FilterIcon,
  userProfiles: UserProfiles,
  business: Business,
  checkBroken: CheckBrokenIcon,
  bank: Bank,
  back: BackIcon,
  add: AddIcon,
  share: ShareIcon,
};

export type FudoIconProps = {
  type: keyof typeof ICONS;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'unset';
};

export const FudoIcon = ({ type, size = 'xl' }: FudoIconProps) => {
  const Icon = ICONS[type];

  return <div className={`fudo-icon fudo-icon--${type} ${size}`}>{Icon && <Icon id={type} />}</div>;
};
