import { Navigate, Outlet } from 'react-router-dom';

import { useAuthStore } from '../store/authStore';

function AuthRoute() {
  const { isAuth } = useAuthStore();

  return isAuth ? <Outlet /> : <Navigate to="/login" replace />;
}

export default AuthRoute;
