import { InfiniteData, QueryClient } from '@tanstack/react-query';

import { deepMerge } from '../helpers/objects';

const STALE_TIME = 1000 * 60 * 5; // 5 minutes
const PAGE_SIZE = 50;
const MAX_PAGE_SIZE = 500;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      retry: false,
    },
  },
});

const onSuccessUpdateSyncItem = <T extends { id: string }>(itemUpdated: T, queryKey: string) => {
  queryClient.setQueryData<T>([queryKey, itemUpdated.id], (prevData) => {
    if (prevData === undefined) return prevData;

    return deepMerge(prevData, itemUpdated);
  });
};

const onSuccessDeleteSyncItem = (queryKey: string[]) => {
  queryClient.setQueryData(queryKey, null);
};

const onSuccessUpdateSyncPagesItem = <T extends { id: string }>(itemUpdated: T, queryKey: string) => {
  queryClient.setQueriesData<InfiniteData<T[], number>>({ queryKey: [queryKey] }, (prevData) => {
    if (prevData === undefined) return prevData;

    const updatedPages = prevData.pages.map((page) => {
      return page.map((item) => (item.id === itemUpdated.id ? deepMerge(item, itemUpdated) : item));
    });

    return { pages: updatedPages, pageParams: prevData.pageParams };
  });
};

const onSuccessAddSyncPagesItem = <T extends { id: string }>(newItem: T, queryKey: string) => {
  queryClient.setQueriesData<InfiniteData<T[], number>>({ queryKey: [queryKey] }, (prevData) => {
    if (prevData === undefined) return prevData;

    const lastPageIndex = prevData.pages.length - 1;
    const lastPage = prevData.pages[lastPageIndex];
    const updatedLastPage = [...lastPage, newItem];

    const updatedPages = [...prevData.pages.slice(0, lastPageIndex), updatedLastPage];

    return { pages: updatedPages, pageParams: prevData.pageParams };
  });
};

const onSuccessDeleteSyncPagesItem = <T extends { id: string }>(itemDeleted: T, queryKey: string) => {
  queryClient.setQueriesData<InfiniteData<T[], number>>({ queryKey: [queryKey] }, (prevData) => {
    if (prevData === undefined) return prevData;

    const updatedPages = prevData.pages.map((page) => {
      return page.filter((item) => item.id !== itemDeleted.id);
    });

    return { pages: updatedPages, pageParams: prevData.pageParams };
  });
};

export {
  queryClient,
  onSuccessUpdateSyncItem,
  onSuccessUpdateSyncPagesItem,
  onSuccessDeleteSyncPagesItem,
  onSuccessDeleteSyncItem,
  onSuccessAddSyncPagesItem,
  PAGE_SIZE,
  MAX_PAGE_SIZE,
};
