import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '../../assets/images/close.svg?react';
import { useFudoAlertBannerStore } from '../../store/fudoAlertBannerStore';
import { FudoButton } from './FudoButton';
import { FudoIcon } from './FudoIcon';

export const FudoAlertBanner = () => {
  const { t } = useTranslation([], { keyPrefix: '_common' });
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { fudoAlertBannerProps } = useFudoAlertBannerStore();

  const closeDialog = () => {
    dialogRef.current?.close();
    setIsLoading(false);
  };

  const showDialog = () => {
    dialogRef.current?.show();
  };

  useEffect(() => {
    fudoAlertBannerProps ? showDialog() : closeDialog();
  }, [fudoAlertBannerProps]);

  if (!fudoAlertBannerProps) return;

  const { content, status, title, acceptText, onAccept } = fudoAlertBannerProps;

  const handleAccept = async () => {
    setIsLoading(true);
    await onAccept?.();
    closeDialog();
  };

  return (
    <div className={`fudo-alert-banner fudo-alert-banner--${status}`}>
      <dialog ref={dialogRef}>
        <div className={`fudo-alert-banner__container fudo-alert-banner__container--${status}`}>
          <div className="fudo-alert-banner__header">
            {status && <FudoIcon type={status} size="md" />}
            <div className="fudo-alert-banner__content">
              {title && <h3>{title}</h3>}
              {content && <p>{content}</p>}
            </div>
            <FudoButton
              variant="transparent"
              icon={<CloseIcon width={'12px'} height={'12px'} />}
              onClick={closeDialog}
            />
          </div>

          <div className="fudo-alert-banner__button">
            <FudoButton
              text={acceptText || t('accept')}
              onClick={handleAccept}
              loading={isLoading}
              variant="primary"
              status={status}
            />
          </div>
        </div>
      </dialog>
    </div>
  );
};
