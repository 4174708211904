import { ReactNode } from 'react';
import { create } from 'zustand';

type FudoSideSheetProps = {
  content?: ReactNode;
  title?: string;
};

type FudoSideSheetStore = {
  fudoSideSheetProps: FudoSideSheetProps;
  isOpen: boolean;
  openSheet: (props: FudoSideSheetProps) => void;
  closeSheet: () => void;
};

const useFudoSideSheetStore = create<FudoSideSheetStore>((set) => ({
  fudoSideSheetProps: {},
  isOpen: false,
  openSheet: (props) => set({ fudoSideSheetProps: props, isOpen: true }),
  closeSheet: () => set({ isOpen: false }),
}));

export { useFudoSideSheetStore };
