import { useEffect, useRef, useState } from 'react';

import CloseIcon from '../../assets/images/close.svg?react';
import { useFudoSnackbarStore } from '../../store/fudoSnackbarStore';
import { FudoButton } from './FudoButton';
import { FudoIcon } from './FudoIcon';

export const FudoSnackbar = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { fudoSnackbarProps } = useFudoSnackbarStore();
  const [isClosing, setIsClosing] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout>();

  const closeDialog = () => {
    if (closeTimeout) clearTimeout(closeTimeout);
    setIsClosing(true);
    setCloseTimeout(
      setTimeout(() => {
        setIsClosing(false);
        dialogRef.current?.close();
      }, 500),
    );
  };

  const showDialog = () => {
    if (closeTimeout) clearTimeout(closeTimeout);
    dialogRef.current?.show();
    setCloseTimeout(setTimeout(closeDialog, 4000));
  };

  useEffect(() => {
    fudoSnackbarProps ? showDialog() : closeDialog();

    return () => {
      if (closeTimeout) clearTimeout(closeTimeout);
    };
  }, [fudoSnackbarProps]);

  if (!fudoSnackbarProps) return;

  const { status, title } = fudoSnackbarProps;

  return (
    <div className={`fudo-snackbar fudo-snackbar--${status}`}>
      <dialog ref={dialogRef}>
        <div className="fudo-snackbar__container">
          <div className={`fudo-snackbar__content fudo-snackbar__content--${status} ${isClosing ? 'close' : ''}`}>
            {status && <FudoIcon type={status} size="md" />}
            <div className="fudo-snackbar__title">{title && <h3>{title}</h3>}</div>
            <FudoButton variant="transparent" icon={<CloseIcon />} onClick={closeDialog} />
          </div>
        </div>
      </dialog>
    </div>
  );
};
