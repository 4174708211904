import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const env = window.__env__.ENV;

if (env === 'production' || env === 'staging') {
  Sentry.init({
    dsn: 'https://3ef489e5b1741f27f408ea90146c2a5e@sentry.fu.do/18',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: env,
    replaysSessionSampleRate: env === 'production' ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
  });
}
