import { cloneDeep, merge } from 'lodash';

const deepMerge = <T, K>(target: T, source: K) => {
  const clonedTarget = cloneDeep(target);
  const clonedSource = cloneDeep(source);

  return merge(clonedTarget, clonedSource);
};

export { deepMerge };
