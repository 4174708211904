import { ReactNode } from 'react';
import { create } from 'zustand';

type FudoBottomSheetProps = {
  content?: ReactNode;
  title?: string;
  onClose?: () => void;
  isLoading?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'fit-content';
  showControls?: boolean;
  acceptText?: string;
  cancelText?: string;
  onAccept?: () => void;
};

type FudoBottomSheetStore = {
  FudoBottomSheetProps: FudoBottomSheetProps;
  isOpen: boolean;
  openSheet: (props: FudoBottomSheetProps) => void;
  closeSheet: () => void;
};

const useFudoBottomSheetStore = create<FudoBottomSheetStore>((set) => ({
  FudoBottomSheetProps: { size: 'fit-content', showControls: true },
  isOpen: false,
  openSheet: (props) => {
    const rootElement = document.getElementById('root');

    if (rootElement) {
      rootElement.style.overflow = 'hidden';
    }
    set((state) => ({ FudoBottomSheetProps: { ...state.FudoBottomSheetProps, ...props }, isOpen: true }));
  },
  closeSheet: () => {
    const rootElement = document.getElementById('root');

    if (rootElement) {
      rootElement.style.overflow = 'auto';
    }
    set({ isOpen: false });
  },
}));

export { useFudoBottomSheetStore };
