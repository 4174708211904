const isInIframe = () => {
  return window.self !== window.top;
};

const postMessage = (type: string, payload: string | object) => {
  if (!isInIframe()) return;

  window.parent.postMessage({ type, payload }, '*');
};

export { isInIframe, postMessage };
