import { ReactNode } from 'react';
import { create } from 'zustand';

type FudoModalProps = {
  content?: ReactNode;
  title?: string;
  onClose?: () => void;
};

type FudoModalStore = {
  fudoModalProps: FudoModalProps;
  isOpen: boolean;
  openModal: (props: FudoModalProps) => void;
  closeModal: () => void;
};

const useFudoModalStore = create<FudoModalStore>((set) => ({
  fudoModalProps: {},
  isOpen: false,
  openModal: (props) => set({ fudoModalProps: props, isOpen: true }),
  closeModal: () => set({ isOpen: false }),
}));

export { useFudoModalStore };
