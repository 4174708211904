import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { getCookie } from '../helpers/cookies';
import { isInIframe } from '../helpers/iframe';
import { queryClient } from '../libs/queryClient';

type AuthStore = {
  isAuth: boolean;
  token: string | null;
  pusherSocketId: string | null;
  logout: () => void;
  setToken: (token: string) => void;
  setPusherSocketId: (token: string) => void;
};

const useAuthStore = create(
  persist<AuthStore>(
    (set) => ({
      isAuth: false,
      token: null,
      pusherSocketId: null,
      logout: () => {
        queryClient.clear();

        set({ isAuth: false, token: null });
      },
      setToken: (token: string) => set({ isAuth: true, token }),
      setPusherSocketId: (socketId: string) => set({ pusherSocketId: socketId }),
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => {
        const storage = localStorage;

        if (isInIframe() && getCookie('token')) {
          const persistedState = JSON.parse(storage.getItem('auth') || '{}');

          storage.setItem(
            'auth',
            JSON.stringify({ ...persistedState, state: { token: getCookie('token'), isAuth: true } }),
          );
        }

        return storage;
      }),
    },
  ),
);

export { useAuthStore };
