import React from 'react';
import classNames from 'classnames';

import { ColorValues } from '../../../interfaces/colors';

export interface FudoTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  weight?: 'light' | 'regular' | 'semi-regular' | 'semi-bold' | 'bold' | 'black';
  size?: 'xss' | 'xs' | 'sm' | 'md' | 'lg';
  decoration?: 'line-through' | 'underline' | 'none';
  align?: 'center' | 'right' | 'left';
  color?: ColorValues;
}

const FudoText: React.FC<FudoTextProps> = ({
  children,
  weight = 'regular',
  decoration,
  className = '',
  size = 'sm',
  align,
  color,
  ...props
}) => {
  return (
    <span
      className={classNames(`fudo-text fudo-text-font-weight--${weight} fudo-text-font-size--${size}`, {
        [`fudo-text-decoration--${decoration}`]: !!decoration,
        [`fudo-text-color--${color}`]: !!color,
        [`fudo-text-align--${align}`]: !!align,
        [className]: !!className,
      })}
      {...props}
    >
      {children}
    </span>
  );
};

export default FudoText;
