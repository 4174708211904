import React from 'react';
import classNames from 'classnames';

import { SpacingValues } from '../interfaces/spacing';

export interface FlexProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  center?: boolean;
  align?: 'start' | 'end' | 'center';
  justify?: 'start' | 'end' | 'center' | 'between';
  grow?: boolean;
  gap?: SpacingValues;
  margin?: SpacingValues;
  marginVertical?: SpacingValues;
  marginHorizontal?: SpacingValues;
  marginTop?: SpacingValues;
  marginRight?: SpacingValues;
  marginBottom?: SpacingValues;
  marginLeft?: SpacingValues;
  padding?: SpacingValues;
  paddingVertical?: SpacingValues;
  paddingHorizontal?: SpacingValues;
  paddingTop?: SpacingValues;
  paddingRight?: SpacingValues;
  paddingBottom?: SpacingValues;
  paddingLeft?: SpacingValues;
  fullWidth?: boolean;
  pointer?: boolean;
}

const Flex: React.FC<FlexProps> = (props) => {
  const {
    className,
    children,
    center,
    justify,
    direction = 'row',
    grow,
    align,
    margin,
    marginHorizontal,
    marginVertical,
    marginLeft,
    marginBottom,
    marginRight,
    marginTop,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    pointer,
    gap,
    fullWidth,
    ...rest
  } = props;

  return (
    <div
      className={classNames(`flex`, {
        'flex--justify-center': center,
        'flex--align-center': center,
        'flex--grow': grow,
        [`flex--direction--${direction}`]: direction,
        [`flex--justify-${justify}`]: justify,
        [`flex--align-${align}`]: align,
        [`flex--m-${margin}`]: margin,
        [`flex--mt-${marginTop || marginVertical}`]: marginTop || marginVertical,
        [`flex--mr-${marginRight || marginHorizontal}`]: marginRight || marginHorizontal,
        [`flex--mb-${marginBottom || marginVertical}`]: marginBottom || marginVertical,
        [`flex--ml-${marginLeft || marginHorizontal}`]: marginLeft || marginHorizontal,
        [`flex--p-${padding}`]: padding,
        [`flex--pt-${paddingTop || paddingVertical}`]: paddingTop || paddingVertical,
        [`flex--pr-${paddingRight || paddingHorizontal}`]: paddingRight || paddingHorizontal,
        [`flex--pb-${paddingBottom || paddingVertical}`]: paddingBottom || paddingVertical,
        [`flex--pl-${paddingLeft || paddingHorizontal}`]: paddingLeft || paddingHorizontal,
        [`flex--gap-${gap}`]: gap,
        [`flex--full-width`]: fullWidth,
        [`flex--pointer`]: pointer,
        [className || '']: className,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flex;
