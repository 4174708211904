import React from 'react';
import classNames from 'classnames';

import { useFudoSideSheetStore } from '../../store/fudoSideSheetStore';
import Flex from '../Flex';
import { FudoIcon } from './FudoIcon';
import FudoText from './typography/FudoText';

const FudoSideSheet: React.FC = () => {
  const {
    isOpen,
    closeSheet,
    fudoSideSheetProps: { content, title },
  } = useFudoSideSheetStore();

  return (
    <div className="side-sheet">
      <div
        className={classNames('side-sheet__backdrop', {
          'side-sheet__backdrop--open': isOpen,
        })}
        onClick={closeSheet}
      />
      <Flex
        className={classNames('side-sheet__container', {
          'side-sheet__container--open': isOpen,
        })}
        direction="column"
        paddingTop="8"
        paddingBottom="8"
      >
        <Flex paddingRight="8" paddingLeft="8" marginBottom="4">
          <FudoText weight="semi-bold" size="md">
            {title}
          </FudoText>
          <Flex center className="side-sheet__container__close-button" onClick={closeSheet}>
            <FudoIcon type="close" size="md" />
          </Flex>
        </Flex>
        <Flex className="side-sheet__container__body" direction="column" grow>
          <Flex paddingRight="8" paddingLeft="8" direction="column" grow>
            {content}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default FudoSideSheet;
