import { create } from 'zustand';

export type FudoAlertBannerProps = {
  title: string;
  content: string;
  status: 'error' | 'informative' | 'success' | 'warning';
  acceptText?: string;
  onAccept?: () => void;
};

type FudoAlertBannerStore = {
  fudoAlertBannerProps: FudoAlertBannerProps | undefined;
  openFudoAlertBanner: (props: FudoAlertBannerProps) => void;
  closeFudoAlertBanner: () => void;
};

const useFudoAlertBannerStore = create<FudoAlertBannerStore>((set) => ({
  fudoAlertBannerProps: undefined,
  openFudoAlertBanner: (props) => set({ fudoAlertBannerProps: props }),
  closeFudoAlertBanner: () => set({ fudoAlertBannerProps: undefined }),
}));

export { useFudoAlertBannerStore };
