import { create } from 'zustand';

export type FudoSnackbarProps = {
  title: string;
  status: 'error' | 'informative' | 'success' | 'warning';
};

type FudoSnackbarStore = {
  fudoSnackbarProps: FudoSnackbarProps | undefined;
  openFudoSnackbar: (props: FudoSnackbarProps) => void;
  closeFudoSnackbar: () => void;
};

const useFudoSnackbarStore = create<FudoSnackbarStore>((set) => ({
  fudoSnackbarProps: undefined,
  openFudoSnackbar: (props) => set({ fudoSnackbarProps: props }),
  closeFudoSnackbar: () => set({ fudoSnackbarProps: undefined }),
}));

export { useFudoSnackbarStore };
