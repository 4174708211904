import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useFudoBottomSheetStore } from '../../store/fudoBottomSheetStore';
import Flex from '../Flex';
import LoadingIcon from '../LoadingIcon';
import { FudoButton } from './FudoButton';
import { FudoIcon } from './FudoIcon';

const FudoBottomSheet: React.FC = () => {
  const [isLoadingAcceptButton, setIsLoadingAcceptButton] = useState(false);
  const { t } = useTranslation();
  const {
    FudoBottomSheetProps: { content, title, onClose, isLoading, size, showControls, acceptText, cancelText, onAccept },
    closeSheet,
    isOpen,
  } = useFudoBottomSheetStore();

  const onCloseSheet = () => {
    closeSheet();
    onClose?.();
  };

  return (
    <div
      className={classNames('fudo-bottom-sheet', {
        'fudo-bottom-sheet--open': isOpen,
      })}
    >
      <div
        className={classNames('fudo-bottom-sheet__backdrop ', {
          'fudo-bottom-sheet__backdrop--open': isOpen,
        })}
        onClick={onCloseSheet}
      />

      <div
        className={classNames('fudo-bottom-sheet__sheet ', {
          'fudo-bottom-sheet__sheet--open': isOpen,
          [`fudo-bottom-sheet__sheet--${size}`]: size,
        })}
      >
        <div className="fudo-bottom-sheet__sheet__header">
          <Flex center className="fudo-bottom-sheet__sheet__close-button" onClick={onCloseSheet}>
            <FudoIcon type="close" size="md" />
          </Flex>
          <span>{title}</span>
        </div>
        {isLoading ? (
          <Flex className="fudo-bottom-sheet__sheet__loading" center>
            <LoadingIcon />
          </Flex>
        ) : (
          content
        )}
        {showControls && (
          <Flex padding="7" gap="6">
            <Flex grow direction="column">
              <FudoButton
                text={cancelText || t('_common.cancel')}
                variant="secondary"
                onClick={onCloseSheet}
                disabled={!isOpen}
              />
            </Flex>
            <Flex grow direction="column">
              <FudoButton
                text={acceptText || t('_common.accept')}
                disabled={!isOpen}
                loading={isLoadingAcceptButton}
                onClick={async () => {
                  try {
                    setIsLoadingAcceptButton(true);
                    await onAccept?.();
                  } finally {
                    onCloseSheet();
                    setIsLoadingAcceptButton(false);
                  }
                }}
              />
            </Flex>
          </Flex>
        )}
      </div>
    </div>
  );
};

export default FudoBottomSheet;
