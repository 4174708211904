import React from 'react';
import classNames from 'classnames';

import { useFudoModalStore } from '../../store/fudoModalStore';

const FudoModal: React.FC = () => {
  const {
    fudoModalProps: { content, onClose },
    closeModal,
    isOpen,
  } = useFudoModalStore();

  const onCloseModal = () => {
    closeModal();
    onClose?.();
  };

  return (
    <div
      className={classNames('modal', {
        'modal--open': isOpen,
      })}
    >
      <div
        className={classNames('modal__backdrop ', {
          'modal__backdrop--open': isOpen,
        })}
        onClick={onCloseModal}
      />

      <div
        className={classNames('modal__content ', {
          'modal__content--open': isOpen,
        })}
      >
        {content}
      </div>
    </div>
  );
};

export default FudoModal;
